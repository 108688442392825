import React from 'react'
import Layout from '../components/layout'
import SEO from "../components/seo"

export default function About() {
  return (
    <div>
      <Layout subTitle="about">
        <SEO title="About" />
        <h2>ME</h2>
        <p>
          I've been doing software development since about 12 years old. 
          In the 
          last 10 years or so I've been focused on how to
          architect and grow technology / software organizations.
          Specifically, I'm interested in the leverage
          cloud services like AWS provide to smart, small and agile startups 
          to compete globally against much more well funded companies.
          A small team of motivated and disciplined developers will win
          every time.
        </p>
        <p>
        <a href="https://www.linkedin.com/in/jaderoberts/" 
              target="_blank" 
              rel="noopener noreferrer"
            >My wife</a> and I live in the Atlanta area with two pups 
          (Rey & Archie) plus a stray
          cat (Holly) who likes the easy meals, walks with the pups and 
          an occaisional warm bed when we can get her in at night.
        </p>
        <p>
          We have two sons - Tripp & Carson. Both are 
          currently in the
          family business. <a href="http://www.tripp-roberts.com/" 
              target="_blank" 
              rel="noopener noreferrer"
            >Tripp </a> is a professional
          developer working in 
          Manhattan.
          <a href="https://www.linkedin.com/in/carson-roberts-8b4296182/" 
              target="_blank" 
              rel="noopener noreferrer"
            > Carson </a> - still in college - is studying CompSci and playing
          <a href="https://www.ugalax.com/" 
              target="_blank" 
              rel="noopener noreferrer"
            > lacrosse </a>.
        </p>
        <h2>THE SITE</h2>

<p>
  This site was built with React and Gatsby - 
  two really great frameworks for building web applications.
</p>
<h3>react</h3>
<p>
  React encourages modular web
  design and helps web app developers "<a href="https://en.wikipedia.org/wiki/Don%27t_repeat_yourself" 
              target="_blank" 
              rel="noopener noreferrer"
            >not repeat themselves</a>"
  better than most other frameworks.
  When architected correctly, very complex sites can
  be built with independent teams that stay within
  the  <a href="http://blog.idonethis.com/two-pizza-team/" 
              target="_blank" 
              rel="noopener noreferrer"
            >two pizza rule</a>.
</p>
<h3>gatsby</h3>
<p>
  Gatsby is a static site generator. It uses convention and a
  few React modules to prebuild static html/js pages from
  a React SPA.
  This can significantly reduce load on the hosting infrastructure.
  It also allows the site to be deployed to a "dumb" service like S3
  - a significant cost saver.
</p>
      </Layout>
    </div>
  )
}
